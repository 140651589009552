<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row
      dense
    >
      <v-col>
        <base-material-card
          icon="mdi-account-plus-outline"
          title="Update User"
          color="#FF4081"
          style="background-color: #FAFAFA"
        >
          <v-col>
            <template v-if="showAlert">
              <v-row>
                <v-col>
                  <v-alert
                    prominent
                    type="error"
                  >
                    That email address is already in use. Please choose another one.
                  </v-alert>
                </v-col>
              </v-row>
            </template>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-card-text class="text-center">
                <v-text-field
                  v-model="user.first_name"
                  class="mb-8"
                  color="#90C74C"
                  label="First Name..."
                  prepend-icon="mdi-card-account-details-outline"
                  required
                  :rules="[v => !!v || 'First name is required']"
                />
                <v-text-field
                  v-model="user.last_name"
                  class="mb-8"
                  color="#90C74C"
                  label="Last Name..."
                  prepend-icon="mdi-card-account-details-outline"
                  required
                  :rules="[v => !!v || 'Last name is required']"
                />
                <v-text-field
                  v-model="user.phone"
                  class="mb-8"
                  color="#90C74C"
                  label="Phone..."
                  prepend-icon="mdi-phone"
                  required
                  :rules="[v => !!v || 'Phone is required']"
                />
                <v-text-field
                  v-model="user.email"
                  class="mb-8"
                  color="#90C74C"
                  label="Email..."
                  prepend-icon="mdi-email"
                  required
                  :rules="[v => !!v || 'Email is required']"
                />
              </v-card-text>
            </v-form>
          </v-col>
          <v-row
            dense
          >
            <v-col>
              <div class="text-right py-2">
                <v-btn
                  elevation="1"
                  color="#90C74C"
                  :disabled="!valid"
                  @click="updateUser"
                >
                  Update User
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noPermissions"
      persistent
      width="600"
    >
      <v-card
        color="warning"
        dark
      >
        <v-alert
          type="warning"
        >
          You do not have permissions to access this feature. Contact Dealer Insights support for further assistance.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="goHome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import UserService from '@/services/UserService.js'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'
  import chatovateCommonMixin from '@/mixins/chatovateCommonMixin.js'

  export default {
    name: 'ChatovateEditUser',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, titleMixin, chatovateCommonMixin],
    data () {
      return {
        showErrorDialog: false,
        items: [],
        search: '',
        loadingMessage: 'Loading',
        loading: true,
        error: '',
        noPermissions: false,
        user: null,
        valid: true,
        showAlert: false,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName', 'permissions']),
    },
    watch: {
      accountId () {
        this.loadUser()
      },
    },
    created () {
      if (!this.permissions.includes('chatovate')) {
        this.noPermissions = true
      }
      this.loadUser()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadUser: function () {
        this.loading = true
        UserService.getUser(this.$route.params.user_id)
          .then(response => {
            this.user = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      updateUser: function () {
        this.loadingMessage = 'Updating User'
        this.loading = true
        const data = {
          id: this.user.id,
          firstName: this.user.first_name,
          lastName: this.user.last_name,
          email: this.user.email,
          phone: this.user.phone,
        }
        ChatovateService.updateProfile(data)
          .then(response => {
            this.loading = false
            this.valid = true
          })
          .catch(error => {
            if (error.response.status === 418) {
              this.showAlert = true
              this.loading = false
              this.valid = true
            } else {
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
              this.loading = false
              this.valid = true
            }
          })
      },
    },
  }
</script>
