export default {
  methods: {
    getWaitingColor (numberCustomers) {
      if (numberCustomers === 0) {
        return 'primary'
      }
      if (numberCustomers > 0) {
        return '#FFB001'
      }
    },
    getWaitingIcon (numberCustomers) {
      if (numberCustomers === 0) {
        return 'mdi-check'
      }
      if (numberCustomers > 0) {
        return 'mdi-exclamation-thick'
      }
    },
  },
}
