import store from '@/store'
import axios from 'axios'

export default {
  getUserToken () {
    var user = store.getters.user
    var token = user ? user.token : ''
    return token
  },

  getUsersByPermission (permission) {
    return axios.get('/api/v1/get_users_by_permissions?permission=' + permission)
  },

  getUsers (accountOnly) {
    return axios.get('/api/v1/users?account_only=' + accountOnly)
  },

  getOpenInvites () {
    return axios.get('/api/v1/open_invites')
  },
  deleteUserInvite (id) {
    return axios.delete('/api/v1/invite?id=' + id)
  },
  createUserInvite (email) {
    return axios.post('/api/v1/create_user_invite?email=' + email)
  },
  deleteUser (id) {
    return axios.delete('/api/v1/users/' + id)
  },
  getUser (id) {
    return axios.get('/api/v1/users/' + id)
  },
}
